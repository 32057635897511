import { changeTheme, changeFont } from "./theme";

(function ($) {
  // TODO: Remove this button helper into a pack that is then availabl gloablly
  jQuery.fn.extend({
    disableButton: function () {
      return this.each(function () {
        $(this).prop("disabled", true).addClass("button--disabled");
      });
    },
    enableButton: function () {
      return this.each(function () {
        $(this).prop("disabled", false).removeClass("button--disabled");
      });
    },
  });

  //Calendar mobiscroll
  mobiscroll.setOptions({
    theme: "ios",
    themeVariant: "light",
  });

  var calendar_edit = mobiscroll.datepicker("#demo-multi-day-edit", {
    controls: ["calendar"],
    display: "inline",
    selectMultiple: true,
    returnFormat: "locale",
    dateFormat: "YYYY/MM/DD",
  });

  //all-modals
  $(".modal-close-btn").on("click", function () {
    $(".modal").modal("hide");
  });

  //photo-modal
  $(".add-production-photo-button").on("click", function () {
    displayAddPhotoSection("Production");
  });

  $(".add-press-photo-button").on("click", function () {
    displayAddPhotoSection("Press");
  });

  $("#photo-back-button").on("click", function () {
    $("#photo-modal-edit-area").html("");

    if ($(".main-gallery-area").is(":hidden")) {
      //if the main area is hidden (on the page to add or edit a photo)
      //it will return to the main area
      $(".add-photos-section").hide();
      $(".main-gallery-area").show();
      $("#photo-modal-title").text(`Editing Photos`);
    } else {
      //if on the main screen. it will close the modal
      $(".modal").modal("hide");
    }
  });

  $("#photos-sort-save-form").show().parent().show();
  $(".photo-modal-sortable").railsSortable({
    // axis: "x", // this feels nicer but is buggy and causes a weird y-offset issue
    tolerance: "pointer",
    change: function (_event, _ui) {
      $("#photos-sort-save-form-button").enableButton();
    },
  });

  function displayAddPhotoSection(type) {
    $("#photos-sort-save-form").hide().parent().hide();
    $("#photo-upload").trigger("click");
    $("#photo-modal-title").text(`Adding ${type} photo`);
    $("#photo-type-input").val(type);
    $(".add-photos-section").show();
    $(".main-gallery-area").hide();
  }

  $("#photo-upload-trigger").on("click", function (e) {
    $("#photo-upload").trigger("click");
    e.preventDefault();
  });

  $("#photo-upload").on("change", function (event) {
    var preview = $("#photo-preview");
    var input = $(event.currentTarget);
    var file = input[0].files[0];
    if (file.size > 5242879) {
      alert("File size is too large. Must be less than 5MB");
    } else {
      var reader = new FileReader();
      reader.onload = function (e) {
        image_base64 = e.target.result;
        preview.css("background-image", "url(" + image_base64 + ")");
      };
      reader.readAsDataURL(file);
    }
  });

  //main profile picture modal
  $(".main-photo-upload-trigger").on("click", function (e) {
    $("#main-photo-upload").trigger("click");
    e.preventDefault();
  });

  $("#main-photo-upload").on("change", function (event) {
    var previewHor = $("#main-photo-preview-horizontal");
    var previewVer = $("#main-photo-preview-vertical");
    var input = $(event.currentTarget);
    var file = input[0].files[0];
    var reader = new FileReader();
    // reader.onload = function(e){
    //     image_base64 = e.target.result;
    //     previewHor.css('background-image', 'url(' + image_base64 + ')');
    //     previewVer.css('background-image', 'url(' + image_base64 + ')');
    // };
    reader.readAsDataURL(file);
  });

  $("#horizontal-profiler-toggler").on("click", function () {
    $(".main-photo-upload-trigger").toggle();
  });

  //management modals

  // claim profile modal
  $(document).on("click", "#claim_profile-button", function () {
    $(".claim_profile-email-input").val("");
  });

  //materials modal
  $("#materials-back-button").on("click", function () {
    $("#materials-title-area").html("Editing Materials");

    if (
      !$(".edit-quote").is(":hidden") ||
      !$("#quote-edit-area").is(":hidden")
    ) {
      $("#quote-edit-area").hide();
      $(".materials").hide();
      $(".quotes-list").show();
      $("#materials-foot").show();
      $("#add-quote-button").show();
      $(".edit-quote").hide();
      $("#edit-quote-save-button").hide();
      $("#materials-save-button").hide();
      $("#materials-form-area").show();
      $("#materials-title-area").html("Editing Quotes");
    } else if ($(".materials").is(":hidden")) {
      //if the main area is hidden (on the page to add or edit a photo)
      //it will return to the main area
      $("#materials-form-area").show();
      $(".full-bio").hide();
      $(".quotes-list").hide();
      $(".edit-quote").hide();
      $(".edit-files").hide();
      $(".materials").show();
      $("#add-quote-button").hide();
      $("#materials-foot").hide();
      $("#supplemental-material-edit-area").hide();
      $("#materials-save-button").hide();
      $("#edit-quote-save-button").hide();
      $("#materials-back-button").hide();
      $("#material-submit-button").disableButton();
    } else {
      //if on the main screen. it will close the modal
      $(".modal").modal("hide");
    }
  });

  $("#edit-full-bio-button").on("click", function () {
    $(".materials").hide();
    $(".full-bio").show();
    $("#materials-save-button").show();
    $("#materials-foot").show();
    $("#materials-title-area").html("Editing Full Biography");
    $("#materials-back-button").show();
  });

  $("#edit-quotes-button").on("click", function () {
    $(".materials").hide();
    $(".quotes-list").show();
    $("#materials-foot").show();
    $("#add-quote-button").show();
    $("#materials-save-button").hide();
    $("#edit-quote-save-button").hide();
    $("#materials-title-area").html("Editing Quotes");
    $("#materials-back-button").show();
  });

  $("#add-quote-button").on("click", function () {
    $(".quotes-list").hide();
    $(".edit-quote").show();
    $("#add-quote-button").hide();
    $("#materials-title-area").html("Adding Quote");
    $("#materials-save-button").show();
    $("#material-submit-button").enableButton();
    $("#materials-back-button").show();
  });

  $("#add-quote-button-2").on("click", function () {
    $(".materials").hide();
    $(".quotes-list").hide();
    $(".edit-quote").show();
    $("#materials-foot").show();
    $("#add-quote-button").hide();
    $("#materials-title-area").html("Adding Quote");
    $("#material-submit-button").enableButton();
    $("#materials-save-button").show();
    $("#edit-quote-save-button").show();
    $("#materials-back-button").show();
  });

  $(".quotes-list-sortable").railsSortable({
    axis: "y",
    tolerance: "pointer",
    change: function (_event, _ui) {
      // quotes has a different implementation
      $("#materials-save-button").show();
    },
  });

  $("#edit-materials-button").on("click", function () {
    // TODO DRY these into a single function and share across all btns?
    $(".materials").hide();
    $("#materials-save-button").hide(); // might need this?
    $("#materials-title-area").html("Editing Supplementary Materials");
    $("#materials-back-button").show();

    // sm uniques
    $("#edit-files").show();
  });

  $("#edit-file")
    .children(".resume-bar__edit")
    .on("click", function () {
      $("#supplemental-file-upload").trigger("click");
    });

  // used when adding a new file, class name is a bit confusing
  $(".edit-files__handler").on("click", function () {
    // get the type out of the clicked item
    var title = this.dataset.title;
    $("#edit-files").hide();
    $("#add-file").show();
    $("#materials-foot").show();
    $("#materials-save-button").show();
    $("#supplemental-file-title").val(title);
    $("#materials-title-area").html("Adding " + title);
    $("#add-file-list").find(".resume-bar__action.button").html(title);
    $("#add-file")
      .children(".edit-files__note")
      .html("Add your " + title + " as a .pdf file");
  });

  // this is used in the add section, the same class is used in edits on an a tag
  $("#add-file-upload-button").on("click", function () {
    $("#supplemental-file-upload").trigger("click");
  });

  $("#supplemental-file-upload").on("change", function () {
    $("#edit-material-save-button").enableButton();
    $("#material-submit-button").enableButton();
    $("#materials-save-button").show();

    // this is shared with the add_edit_supplemental_material_area.js.erb
    // we should really dry it up ..
    var addFileList = $("#add-file-list");
    var filename = this.files[0].name;
    addFileList.find(".resume-bar__uploaded").html("Last updated just now");
    addFileList.find(".resume-bar__filename").html(filename);
    addFileList.find(".resume-bar__action").attr("href", "#");
    addFileList.show();
    $("#add-file-upload-button").hide();
    addFileList
      .find(".resume-bar__link")
      .unbind()
      .on("click", function () {
        $("#supplemental-file-upload").trigger("click");
      });
  });

  //management modals
  $("#management-back-button").on("click", function () {
    $("#management-title-area").html("Editing Inquiries & Management");
    $("#management-main-modal-body").show();
    $("#management-add-form").hide();
    $("#update-user-inquiry-save-foot").show();
  });

  $("#management-close-button")
    .unbind()
    .on("click", function () {
      $(".modal").modal("hide");

      // Adding a settimeout function to reset the modal after 500ms so user does not
      // see the reset being done in UI and UI looks seamless
      setTimeout(function () {
        // reset to base state
        $("#management-main-modal-body").show();
        $("#home-affiliation-body").show();
        $("#management-back-button").hide();
        $("#management-title-area").html("Editing Inquiries & Management");

        // remove other modal states
        $("#management-add-form").hide();
        $("#modal-foot-management-add").hide();
        $("#edit-management-edit-area").hide();
        $("#management-title-area").html("Editing Inquiries & Management");
        $("#update-user-inquiry-save-foot").show();
      }, 500);
    });

  $("#management-add-new-form-btn").on("click", function () {
    $("#management-add-form").show();
    $("#modal-foot-management-add").show();
    $("#management-back-button").show();
    $("#management-title-area").html("Adding New Management");

    $("#management-main-modal-body").hide();
    $("#update-user-inquiry-save-foot").hide();

    if (
      $("#management-organization-search-text-container").hasClass("hidden")
    ) {
      $("#management-organization-search-text-container").removeClass("hidden");
    }
    $(".organization-search-text-area").val("");
    $(".management-organization-id-field").val("");
    $(".organization.management-suggest").html("");

    if ($("#management-user-search-text-container").hasClass("hidden")) {
      $("#management-user-search-text-container").removeClass("hidden");
    }
    $(".user-search-text-area").val("");
    $(".management-user-id-field").val("");
    $(".manager.management-suggest").html("");
  });

  // when toggling inquiry preferences
  $("div.inquiry__channel").on("click", function () {
    $("#update-user-inquiry-save-button > input").enableButton();
    var stagetimeIsChecked = document.getElementById(
      "performing_artist_display_self_managed_stagetime"
    ).checked;
    var emailIsChecked = document.getElementById(
      "performing_artist_display_self_managed_email"
    ).checked;

    stagetimeIsChecked
      ? $("#artist-inquiry-stagetime").show()
      : $("#artist-inquiry-stagetime").hide();
    emailIsChecked
      ? $("#artist-inquiry-email").show()
      : $("#artist-inquiry-email").hide();

    channels = [];
    if (stagetimeIsChecked) {
      channels.push("Stagetime message");
    }
    if (emailIsChecked) {
      channels.push("Email");
    }

    var messageText =
      stagetimeIsChecked || emailIsChecked
        ? `This user is accepting inquiries via ${channels.join(" and ")}`
        : "No inquiry card will show on your profile.";

    $(".inquiry__description").find("span").text(messageText);
  });

  // Affiliation modal
  $("#affiliation-back-button")
    .unbind()
    .on("click", function () {
      if ($("#home-affiliation-body").is(":hidden")) {
        $("#modal-foot-affiliation-home").show();
        $("#home-affiliation-body").show();
        $("#affiliation-add-form").hide();
        $("#affiliation-edit-form").hide();
        $("#career-title-area").html("Edit Career Highlights/Education");
        $("#affiliation-back-button").hide();
      } else {
        $(".modal").modal("hide");
      }
    });

  $("#non-performer-edit-handler").on("click", function () {
    if (document.getElementById("affiliations_size").innerHTML == 0) {
      $("#modal-foot-affiliation-home").hide();
      $("#home-affiliation-body").hide();
      $("#affiliation-back-button").hide();
      $("#affiliation-add-form").show();
      $("#career-title-area").html("Adding Career Highlight/Education");
    }
  });

  $(".input__widget").on("keyup click change", function () {
    let affiliation_org_val = document.getElementById(
      "affiliation-organization-search-text-value"
    ).value;
    let affiliation_org = document.getElementById(
      "affiliation-organization-search-text-field"
    ).value;
    let affiliation_role = document.getElementById(
      "affiliation-role-value"
    ).value;
    let add_affiliation_role = document.getElementById(
      "add-affiliation-role-value"
    ).value;
    if (affiliation_org == "") {
      $("#schedule-organization-search-text-container").addClass(
        "input--error"
      );
      $(".output-affiliation-org").removeClass("hidden");
      $("#affiliation-role-container").removeClass("input--error");
      $(".output-affiliation-role").addClass("hidden");
    } else if (affiliation_role == "") {
      if (add_affiliation_role != "") {
        $("#schedule-organization-search-text-container").removeClass(
          "input--error"
        );
        $(".output-affiliation-org").addClass("hidden");
        $("#affiliation-role-container").removeClass("input--error");
        $(".output-affiliation-role").addClass("hidden");
      } else {
        $("#affiliation-role-container").addClass("input--error");
        $(".output-affiliation-role").removeClass("hidden");
        $("#schedule-organization-search-text-container").removeClass(
          "input--error"
        );
        $(".output-affiliation-org").addClass("hidden");
      }
    } else {
      $("#schedule-organization-search-text-container").removeClass(
        "input--error"
      );
      $(".output-affiliation-org").addClass("hidden");
      $("#affiliation-role-container").removeClass("input--error");
      $(".output-affiliation-role").addClass("hidden");
    }
    if (affiliation_org != "" && affiliation_role != "") {
      $("#save-affiliation-edit").removeClass("button--disabled");
      $("#save-affiliation-edit").removeClass("unclickable");
    } else {
      $("#save-affiliation-edit").addClass("button--disabled");
      $("#save-affiliation-edit").addClass("unclickable");
    }
    if (affiliation_org != "" && add_affiliation_role != "") {
      $("#save-affiliation-edit").removeClass("button--disabled");
      $("#save-affiliation-edit").removeClass("unclickable");
    } else {
      $("#save-affiliation-edit").addClass("button--disabled");
      $("#save-affiliation-edit").addClass("unclickable");
    }
    if (affiliation_org_val != "") {
      $("#schedule-organization-search-text-container").removeClass(
        "input--error"
      );
      let red_message = document.getElementById("output-affiliation-org-id");
      red_message.innerHTML = "Organization not selected";
    } else {
      $("#schedule-organization-search-text-container").addClass(
        "input--error"
      );
    }
  });

  $(".input__suggest").on("click", function () {
    $("#add-affiliation-role-value").click();
  });

  $("#affiliation-close-button")
    .unbind()
    .on("click", function () {
      $(".modal").modal("hide");

      // Adding a settimeout function to reset the modal after 500ms so user does not
      // see the reset being done in UI and UI looks seamless
      setTimeout(function () {
        $("#modal-foot-affiliation-home").show();
        $("#home-affiliation-body").show();
        $("#affiliation-add-form").hide();
        $("#affiliation-edit-form").hide();
        $("#career-title-area").html("Edit Career Highlights/Education");
        $("#affiliation-back-button").hide();
      }, 500);
    });

  $("#add-affiliation-button").on("click", function () {
    $("#modal-foot-affiliation-home").hide();
    $("#home-affiliation-body").hide();
    $("#affiliation-back-button").show();
    $("#affiliation-add-form").show();
    $("#career-title-area").html("Adding Career Highlight/Education");
  });

  $(".add-first-affiliation-button").on("click", function () {
    $("#modal-foot-affiliation-home").hide();
    $("#home-affiliation-body").hide();
    $("#affiliation-back-button").hide();
    $("#affiliation-add-form").show();
    $("#career-title-area").html("Adding Career Highlight/Education");
  });

  $(".affiliation-currently-affiliated-checkbox").on("change", function () {
    if ($(this).prop("checked")) {
      $(this)
        .closest(".employment")
        .children(".affiliation-future-dates")
        .hide();
    } else {
      $(this)
        .closest(".employment")
        .children(".affiliation-future-dates")
        .show();
    }
  });

  $(".employment-current-position-checkbox").on("change", function () {
    if ($(this).prop("checked")) {
      $(this).closest(".modal-body").children(".employement-end-date").hide();
    } else {
      $(this).closest(".modal-body").children(".employement-end-date").show();
    }
  });

  $(".affiliation-education-checkbox").on("click", function () {
    var $this = $(this),
      $container = $this.closest(".employment");
    let role = document.getElementById("output-affiliation-role-id");
    if ($this.prop("checked")) {
      $container
        .find(".affiliation-show-field")
        .attr("placeholder", "Field of Study");
      $container
        .find(".affiliation-role-field")
        .attr("placeholder", "Degree or Program*");
      $container.find(".end-year-field").html("End year (or expected)");
      $container.find(".end-month-field").html("End month (or expected)");
      $container
        .find(".currently-affiliated-checkbox-container")
        .addClass("hidden");
      role.innerHTML = "Degree is required.";
    } else {
      $container
        .find(".affiliation-show-field")
        .attr("placeholder", "Production, Show, or Work");
      $container
        .find(".affiliation-role-field")
        .attr("placeholder", "Role, Title, or Position *");
      $container.find(".end-year-field").html("End year");
      $container.find(".end-month-field").html("End month");
    }
  });

  $(".affiliation-career-checkbox").on("click", function () {
    var $this = $(this),
      $container = $this.closest(".employment");
    let role = document.getElementById("output-affiliation-role-id");
    if ($this.prop("checked")) {
      $container
        .find(".affiliation-show-field")
        .attr("placeholder", "Production, Show, or Work");
      $container
        .find(".affiliation-role-field")
        .attr("placeholder", "Role, Title, or Position *");
      $container.find(".end-year-field").html("End year");
      $container.find(".end-month-field").html("End month");
      $container
        .find(".currently-affiliated-checkbox-container")
        .removeClass("hidden");
      role.innerHTML = "Role, Title, or Position is required.";
    }
  });

  $(".affiliations-sortable").railsSortable({
    axis: "y",
    tolerance: "pointer",
    change: function (_event, _ui) {
      $("#affiliations-sort-save-form-button").enableButton();
    },
  });

  function debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }
  var me;
  $(".organization-search-text-area").on("keyup", function () {
    me = $(this).val();
    $("#output-schedule-org-id").show();
  });
  $(".organization-search-text-area").on(
    "keyup",
    debounce(() => {
      if (me.length >= 3) {
        $.get(`/affiliation_organization_search/${me}`, function (data) {
          var targetElement = $(".organization-search-dropdown");
          var elementsToAddContainer = $($.parseHTML(data));
          targetElement.html(elementsToAddContainer.find(".input__options"));
          targetElement.append(elementsToAddContainer.find(".null-join"));
          targetElement.show();
        });
      } else {
        var targetElement = $(".organization-search-dropdown");
        targetElement.html("");
        targetElement.hide();
      }
    }, 300)
  );

  $("#edit-schedule").on("hidden.bs.modal", function () {
    $(".organization-search-text-area").val("");
    $("#output-schedule-org-id").hide();
    var targetElement = $(".organization-search-dropdown");
    targetElement.html("");
    targetElement.hide();
    $(".management-suggest__delete").click();
  });

  $("#edit-experience").on("hidden.bs.modal", function () {
    $(".organization-search-text-area").val("");
    $("#output-schedule-org-id").hide();
    var targetElement = $(".organization-search-dropdown");
    targetElement.html("");
    targetElement.hide();
    $(".management-suggest__delete").click();
  });

  $("#edit-media").on("hidden.bs.modal", function () {
    $(".organization-search-text-area").val("");
    $("#output-schedule-org-id").hide();
    var targetElement = $(".organization-search-dropdown");
    targetElement.html("");
    targetElement.hide();
    $(".management-suggest__delete").click();
  });

  $("#edit-roster").on("hidden.bs.modal", function () {
    $(".organization-search-text-area").val("");
    $("#output-schedule-org-id").hide();
    var targetElement = $(".organization-search-dropdown");
    targetElement.html("");
    targetElement.hide();
    $(".management-suggest__delete").click();
  });

  $("#edit-career").on("hidden.bs.modal", function () {
    $(".organization-search-text-area").val("");
    $("#output-schedule-org-id").hide();
    var targetElement = $(".organization-search-dropdown");
    targetElement.html("");
    targetElement.hide();
    $(".management-suggest__delete").click();
  });
  //Features modals

  $("#add-file-feature-button").on("click", function () {
    $("#submit-new-feature").show();
    $("#save-drag-and-drop").hide();
    $(".edit-materials__form").show();
    $("#modal-feature-home").hide();
    $("#features-back-button").show();
    $("#upload_file_bar").show();
    $("#feature-add-link-input").hide();
    $("#features-edit-area").hide();
    $("#home-feature-body").show();
    document.getElementById("feature_kind_field").value = "file";
  });

  $("#add-link-feature-button").on("click", function () {
    $("#submit-new-feature").show();
    $("#save-drag-and-drop").hide();
    $(".edit-materials__form").show();
    $("#modal-feature-home").hide();
    $("#features-back-button").show();
    $("#upload_file_bar").hide();
    $("#feature-add-link-input").show();
    $("#features-edit-area").hide();
    $("#home-feature-body").show();
    $(".landscape-document").hide();
    $(".output-new-file").hide();
    $("#feature-add-file-upload")[0].value = null;
    document.getElementById("feature_kind_field").value = "link";
  });

  $("#features-back-button").on("click", function () {
    $("#submit-new-feature").hide();
    $("#save-drag-and-drop").show();
    $(".edit-materials__form").hide();
    $("#modal-feature-home").show();
    $("#features-back-button").hide();
    $("#home-feature-body").show();
    $("#features-edit-area").hide();
    $("#features-back-button").hide();
    $(".output-new-file").show();
  });

  $(document).on("click", ".feature-add-file-button", function (e) {
    $("#feature-add-file-upload").trigger("click");
    e.preventDefault();
  });
  $(document).on("change", "#feature-add-file-upload", function () {
    // var preview = $("#photo-preview");
    var input = $("#feature-add-file-upload");
    var file = input[0].files[0];
    if (file.size > 5242879 * 2) {
      alert("File size is too large. Must be less than 10MB");
    } else {
      var reader = new FileReader();
      reader.onload = function (e) {
        image_base64 = e.target.result;
        // preview.css('background-image', 'url(' + image_base64 + ')');
      };
      reader.readAsDataURL(file);
      $(".feature-add-file-button").hide();
      $(".landscape-document").show();
      $(".landscape-document__label").html(file.name);
      let feature_title = document.getElementById(
        "feature-add-title-field"
      ).value;
      let feature_file = $("#feature-add-file-upload")[0].value;
      if (feature_title != "" && feature_file != "") {
        $("#save-new-feature").removeClass("button--disabled");
        $("#save-new-feature").removeClass("unclickable");
        $("#upload_file_bar").removeClass("upload-bar--error");
        $(".output-new-file").addClass("hidden");
      } else {
        $("#save-new-feature").addClass("button--disabled");
        $("#save-new-feature").addClass("unclickable");
        $("#feature-add-title-input").addClass("input--error");
        $(".output-feature-title").removeClass("hidden");
      }
    }
  });

  $(document).on("click", ".landscape-document__delete", function (e) {
    $(".feature-add-file-button").show();
    $(".landscape-document").hide();
    $("#feature-add-file-upload")[0].value = null;
    $("#save-new-feature").addClass("button--disabled");
    $("#save-new-feature").addClass("unclickable");
    $("#upload_file_bar").addClass("upload-bar--error");
    $(".output-new-file").removeClass("hidden");
  });

  $(document).on("click", ".feature-edit-file-button", function (e) {
    $("#feature-edit-file-upload").trigger("click");
    e.preventDefault();
  });
  $(document).on("change", "#feature-edit-file-upload", function () {
    // var preview = $("#photo-preview");
    var input = $("#feature-edit-file-upload");
    var file = input[0].files[0];
    if (file.size > 5242879 * 2) {
      alert("File size is too large. Must be less than 10MB");
    } else {
      var reader = new FileReader();
      reader.onload = function (e) {
        image_base64 = e.target.result;
        // preview.css('background-image', 'url(' + image_base64 + ')');
      };
      reader.readAsDataURL(file);
      $(".feature-edit-file-button").hide();
      $(".landscape-document").show();
      $(".landscape-document__label").html(file.name);
      let feature_title = document.getElementById(
        "feature-edit-title-field"
      ).value;
      let feature_file = $("#feature-edit-file-upload")[0].value;
      if (feature_title != "" && feature_file != "") {
        $("#save-features-edit").removeClass("button--disabled");
        $("#save-features-edit").removeClass("unclickable");
      } else {
        $("#save-features-edit").addClass("button--disabled");
        $("#save-features-edit").addClass("unclickable");
      }
      $(".output-edit-file").addClass("hidden");
    }
  });

  $(document).on("click", ".edit-landscape-document__delete", function (e) {
    $(".feature-edit-file-button").show();
    $(".landscape-document").hide();
    $("#feature-edit-file-upload")[0].value = null;
    $("#save-features-edit").addClass("button--disabled");
    $("#save-features-edit").addClass("unclickable");
    $("#upload_edit_file_bar").addClass("upload-bar--error");
    $(".output-edit-file").removeClass("hidden");
  });

  $(".features-sortable").railsSortable({
    axis: "y",
    tolerance: "pointer",
    change: function (_event, _ui) {
      $("#save-sorteable-features").removeClass("button--disabled");
      $("#save-sorteable-features").removeClass("unclickable");
    },
  });

  //Schedule modal
  $("#schedule-back-button")
    .unbind()
    .on("click", function () {
      if ($("#home-schedule-body").is(":hidden")) {
        $("#modal-foot-schedule-home").show();
        $("#home-schedule-body").show();
        $("#schedule-add-form").hide();
        $("#schedule-edit-area").hide();
        $("#schedule-title-area").html("Edit Schedule");
        $("#schedule-back-button").hide();
      } else {
        $(".modal").modal("hide");
      }
    });

  $("#schedule-close-button")
    .unbind()
    .on("click", function () {
      $(".modal").modal("hide");

      // Adding a settimeout function to reset the modal after 500ms so user does not
      // see the reset being done in UI and UI looks seamless
      setTimeout(function () {
        $("#modal-foot-schedule-home").show();
        $("#home-schedule-body").show();
        $("#schedule-add-form").hide();
        $("#schedule-edit-area").hide();
        $("#schedule-title-area").html("Edit Schedule");
        $("#schedule-back-button").hide();
      }, 500);
    });

  $("#add-schedule-button")
    .unbind()
    .on("click", function () {
      $("#schedule-back-button").show();
      $("#modal-foot-schedule-home").hide();
      $("#home-schedule-body").hide();
      $("#schedule-add-form").show();
      $("#schedule-title-area").html("Adding Schedule");
    });

  $(".filtering__item").on("click", function () {
    $(".edit-schedule__interval").removeClass("filtering__item--current");
    $(this).addClass("filtering__item--current");
    $(".edit-schedule__current").hide();

    // if we are on the current_schedule button, we need to display
    // schedules where we are currently performing ...
    // otherwise we show by year
    if (this.id == "current_schedule") {
      $(".edit-schedule__current.upcoming_schedule").show();
    } else {
      $(`.schedule_modal_${$(this).text().trim()}`).show();
    }
  });

  $(".edit-event__add-by-year").on("click", function () {
    $(".edit-event__by-year").hide();
    $(".edit-event__by-day").show();
    $("#schedule-start-date-add").val("");
    $("#schedule-end-date-add").val("");
    $("#schedule-start-date-edit").val("");
    $("#schedule-end-date-edit").val("");
  });

  $(".edit-event__add-by-day").on("click", function () {
    $(".edit-event__by-year").show();
    $(".edit-event__by-day").hide();
  });

  $("#schedule-submit-button-edit").on("click", function () {
    // $("#calendar_dates-edit").val(calendar_edit.getVal());
  });

  $("#demo-multi-day-edit").click(function () {
    $("#schedule-start-date-add").val("");
    $("#schedule-end-date-add").val("");
    $("#schedule-start-date-edit").val("");
    $("#schedule-end-date-edit").val("");
  });

  $("#schedule-add-date-tba-check").on("click", function () {
    if ($("#schedule-add-all-date-area").is(":hidden")) {
      $("#schedule-add-all-date-area").show();
    } else {
      $("#schedule-add-all-date-area").hide();
    }
  });

  $("#schedule-edit-date-tba-check").on("click", function () {
    if ($("#schedule-edit-all-date-area").is(":hidden")) {
      $("#schedule-edit-all-date-area").show();
    } else {
      $("#schedule-edit-all-date-area").hide();
    }
  });

  //media modal
  $("#media-back-button")
    .unbind()
    .on("click", function () {
      if ($("#home-media-body").is(":hidden")) {
        $("#modal-foot-media-home").show();
        $("#home-media-body").show();
        $("#audio-add-form").hide();
        $("#video-add-form").hide();
        $("#project-form-wrapper #project-add-form").hide();
        $("#media-edit-area").hide();
        $("#media-title-area").html("Editing Media");
        $("#media-back-button").hide();
        $("#media-sort-save-form-footer").show();
      } else {
        $(".modal").modal("hide");
      }
    });

  $(".edit-profile-media__list.sortable-multiple-classes").railsSortable({
    axis: "y",
    tolerance: "pointer",
    change: function (_event, _ui) {
      $("#media-sort-save-form-button").enableButton();
    },
  });

  $("#add-audio-button").on("click", function () {
    $("#modal-foot-media-home").hide();
    $("#home-media-body").hide();
    $("#audio-add-form").show();
    $("#media-title-area").html("Adding Audio");
    $("#media-back-button").show();
    $("#media-sort-save-form-footer").hide();
  });

  $("#add-video-button").on("click", function () {
    $("#modal-foot-media-home").hide();
    $("#home-media-body").hide();
    $("#video-add-form").show();
    $("#media-title-area").html("Adding Video");
    $("#media-back-button").show();
    $("#media-sort-save-form-footer").hide();
  });

  $(".video-add-cover-image-button").click(function (e) {
    $("#video-add-cover-upload").trigger("click");
    e.preventDefault();
  });

  $("#video-add-cover-upload").on("change", function () {
    var video_card = $("#video-card-add-preview");
    var input = $(this);
    var file = input[0].files[0];
    if (file.size <= 5242879) {
      var reader = new FileReader();
      reader.onload = function (e) {
        image_base64 = e.target.result;
        video_card.css("background-image", "url(" + image_base64 + ")");
      };
      reader.readAsDataURL(file);
      $("#video-add-card").show();
      $("#video-add-no-photo-area").hide();
    }
  });

  $("#cancel-cropper-media-modal").click(function (e) {
    $("#video-add-card").hide();
    $("#video-add-no-photo-area").show();
    document.getElementById("video-add-cover-upload").value = "";
  });

  $(".video-edit-cover-image-button")
    .unbind()
    .click(function (e) {
      $("#video-edit-cover-upload").trigger("click");
      e.preventDefault();
    });

  $("#video-edit-cover-upload").on("change", function () {
    var video_card = $(".full-media-card__image");
    var input = $(this);
    var file = input[0].files[0];
    if (file.size <= 5242879) {
      var reader = new FileReader();
      reader.onload = function (e) {
        image_base64 = e.target.result;
        video_card.css("background-image", "url(" + image_base64 + ")");
        document.getElementById("video-card-edit-preview").src = image_base64;
      };
      reader.readAsDataURL(file);
    }
  });

  // $(".video-edit-cover-image-button").click(function(e){
  //     $("#video-edit-cover-upload").trigger('click');
  //     e.preventDefault();
  // });

  $("#video-add-title-field").on("keyup", function () {
    $("#video-add-title-area").text($(this).val());
    let video_title_area = document.getElementById(
      "video-add-title-field"
    ).value;
    if (video_title_area == "") {
      $("#video-add-title-area").text("Title");
    }
  });

  $("#video-add-subtitle-field").on("keyup", function () {
    $("#video-add-subtitle-area").text($(this).val());
    let video_subtitle_area = document.getElementById(
      "video-add-subtitle-field"
    ).value;
    if (video_subtitle_area == "") {
      $("#video-add-subtitle-area").text("Subtitle");
    }
  });

  $("#video-edit-title-field").on("keyup", function () {
    $("#video-edit-title-area").text($(this).val());
    let video_title_area = document.getElementById(
      "video-edit-title-field"
    ).value;
    if (video_title_area == "") {
      $("#video-edit-title-area").text("Title");
    }
  });

  $("#video-edit-subtitle-field").on("keyup", function () {
    $("#video-edit-subtitle-area").text($(this).val());
    let video_subtitle_area = document.getElementById(
      "video-edit-subtitle-field"
    ).value;
    if (video_subtitle_area == "") {
      $("#video-edit-subtitle-area").text("Subtitle");
    }
  });

  $(".audio-add-file-button")
    .unbind()
    .on("click", function (e) {
      $("#add-track-upload").trigger("click");
      e.preventDefault();
    });

  $(".audio-edit-file-button")
    .unbind()
    .on("click", function (e) {
      $("#edit-track-upload").trigger("click");
      e.preventDefault();
    });

  $("#add-track-upload").on("change", function () {
    //TODO: update player
    // var video_card = $("#video-card-add-preview")
    var input = $(this);
    var file = input[0].files[0];
    if (file.size > 5242879 * 2) {
      alert("File size is too large. Must be less than 10MB");
    } else {
      $("#audio-add-card").show();
      $("#audio-add-no-file-area").hide();
    }
    // var reader = new FileReader();
    // reader.onload = function(e){
    //     image_base64 = e.target.result;
    //     video_card.css('background-image', 'url(' + image_base64 + ')');
    // };
    // reader.readAsDataURL(file);
  });

  $("#edit-track-upload").on("change", function () {
    //TODO: update player
    // var video_card = $("#video-card-add-preview")
    // var input = $(this);
    // var file = input[0].files[0];
    // var reader = new FileReader();
    // reader.onload = function(e){
    //     image_base64 = e.target.result;
    //     video_card.css('background-image', 'url(' + image_base64 + ')');
    // };
    // reader.readAsDataURL(file);
  });

  $("#audio-add-title-field").on("keyup", function () {
    $("#audio-add-title-area").text($(this).val());
    let video_title_area = document.getElementById(
      "audio-add-title-field"
    ).value;
    if (video_title_area == "") {
      $("#audio-add-title-area").text("Title");
    }
  });

  $("#audio-add-subtitle-field").on("keyup", function () {
    $("#audio-add-subtitle-area").text($(this).val());
    let video_title_area = document.getElementById(
      "audio-add-subtitle-field"
    ).value;
    if (video_title_area == "") {
      $("#audio-add-subtitle-area").text("Subtitle");
    }
  });

  $("#audio-edit-title-field").on("keyup", function () {
    $("#audio-edit-title-area").text($(this).val());
    let video_title_area = document.getElementById(
      "audio-edit-title-field"
    ).value;
    if (video_title_area == "") {
      $("#audio-edit-title-area").text("Title");
    }
  });

  $("#audio-edit-subtitle-field").on("keyup", function () {
    $("#audio-edit-subtitle-area").text($(this).val());
    let video_title_area = document.getElementById(
      "audio-edit-subtitle-field"
    ).value;
    if (video_title_area == "") {
      $("#audio-edit-subtitle-area").text("Subtitle");
    }
  });
  //profile_header
  $("#horizontal-profile-button")
    .unbind()
    .on("click", function () {
      $("#performer-profile").show();
      $("#non-performer-profile").hide();
      $("#horizontal-artist-profile").show();
      $("#vertical-artist-profile").hide();
      $("#customize-header-button").removeClass("button--disabled");
    });

  $("#vertical-profile-button")
    .unbind()
    .on("click", function () {
      $("#performer-profile").show();
      $("#non-performer-profile").hide();
      $("#horizontal-artist-profile").hide();
      $("#vertical-artist-profile").show();
      $("#customize-header-button").removeClass("button--disabled");
    });

  $("#classic-profile-button")
    .unbind()
    .on("click", function () {
      $("#performer-profile").hide();
      $("#non-performer-profile").show();
      $("#customize-header-button").removeClass("button--disabled");
    });

  $(".customize-theme-fonts").on("click", function () {
    var font = $(this).data("layout-type");
    var heading = $(this)
      .children(".layout-type__heading")
      .children("span")
      .first()
      .html();
    var description = $(this).children(".layout-type__description").html();
    var locked = $(this).data("locked");
    var fontValue = $(this).data("font");

    $(".layout-type-font .font-preview")
      .removeClass()
      .addClass("font-preview " + font);
    $(".layout-type-font .layout-type__heading")
      .removeClass()
      .addClass("layout-type__heading " + font);
    $(".layout-type-font .layout-type__description").html(description);
    $("#profile-font-field").val(font);
    $("#web-mode-profile-font-field").val(font);

    if (locked) {
      $(".publish_btn").addClass("locked");
    } else {
      $(".publish_btn").removeClass("locked");
    }

    changeFont(fontValue);
  });

  const rgba2hex = (rgba) =>
    `#${rgba
      .match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/)
      .slice(1)
      .map((n, i) =>
        (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n))
          .toString(16)
          .padStart(2, "0")
          .replace("NaN", "")
      )
      .join("")}`;

  $(".customize-theme-colors").on("click", function () {
    var color = $(this).data("layout-type");
    var heading = $(this)
      .children(".layout-type__heading")
      .children(".layout-type__heading-text")
      .html();
    var locked = $(this).data("locked");
    var theme = $(this).data("theme");

    $(".layout-type-color").removeClass(
      "layout-type--dani_california layout-type--center_stage layout-type--thank_you_five"
    );
    $(".layout-type-color").removeClass(
      "layout-type--and_scene layout-type--tango_roxanne layout-type--jolene layout-type--spotlight"
    );
    $(".layout-type-color").removeClass(
      "layout-type--tarzan_boy layout-type--emerald_city layout-type--sage_the_gemini layout-type--azul_carter_knowles"
    );
    $(".layout-type-color").removeClass(
      "layout-type--hi_barbie layout-type--orinoco_flow layout-type--thank_u_next layout-type--solo_jazz"
    );
    $(".layout-type-color").removeClass(
      "layout-type--cowboy_take_me_away layout-type--critical_edition"
    );

    $(".layout-type-color").addClass("layout-type--" + color);
    $(
      ".layout-type-color .layout-type__heading .layout-type__heading-text"
    ).html(heading);

    $("#profile-color-field").val(
      rgba2hex(
        $(".layout-type__color-block").css("background-color")
      ).toUpperCase()
    );

    $("#web-mode-profile-color-field").val(
      rgba2hex(
        $(".layout-type__color-block").css("background-color")
      ).toUpperCase()
    );

    if (locked) {
      $(".publish_btn").addClass("locked");
    } else {
      $(".publish_btn").removeClass("locked");
    }
    changeTheme(theme);
  });

  // $("#profile-color-button-pink").unbind().on("click", function(){
  //     $(".color-selection__item").removeClass("color-selection__item--current");
  //     $(this).addClass("color-selection__item--current");
  //     $("#profile-color-field").val('#E4CFC3')
  //     //TODO: change profile color
  //     changeTheme($(this).data('theme'));
  //     $("#customize-header-button").removeClass("button--disabled")
  // });

  // $("#profile-color-button-green").unbind().on("click", function(){
  //     $(".color-selection__item").removeClass("color-selection__item--current");
  //     $(this).addClass("color-selection__item--current");
  //     $("#profile-color-field").val('#B3BEB2')
  //     //TODO: change profile color
  //     changeTheme($(this).data('theme'));
  //     $("#customize-header-button").removeClass("button--disabled")

  // });

  // $("#profile-color-button-blue").unbind().on("click", function(){
  //     $(".color-selection__item").removeClass("color-selection__item--current");
  //     $(this).addClass("color-selection__item--current");
  //     $("#profile-color-field").val('#9CB2BE')
  //     //TODO: change profile color
  //     changeTheme($(this).data('theme'));
  //     $("#customize-header-button").removeClass("button--disabled")

  // });

  // $("#profile-color-button-black").unbind().on("click", function(){
  //     $(".color-selection__item").removeClass("color-selection__item--current");
  //     $(this).addClass("color-selection__item--current");
  //     $("#profile-color-field").val('#8B8B8B')
  //     //TODO: change profile color
  //     changeTheme($(this).data('theme'));
  //     $("#customize-header-button").removeClass("button--disabled")

  // });

  $(document).ready(function () {
    $(".chosen-select-show").select2({
      tags: true,
      placeholder: "Show/Event Title *",
      allowClear: true,
      maximumSelectionLength: 1,
      createTag: function (params) {
        var term = $.trim(params.term);
        if (term === "") {
          return null;
        }
        return {
          id: term,
          text: "Add  ' " + term + " '",
          newTag: true, // add additional parameters
        };
      },
      insertTag: function (data, tag) {
        // Insert the tag at the end of the results
        data.push(tag);
        data.reverse();
      },
    });
    $(".chosen-select-show").on("select2:select", function (e) {
      $("#composer_id").click();
      var value = $(this).val();
      var select = document.getElementById("affiliation-role-value");
      var i,
        L = select.options.length - 1;
      for (i = L; i >= 0; i--) {
        select.remove(i);
      }
      select.remove(0);
      $.ajax({
        url: "/get_rols",
        type: "get",
        data: { show: value },
        success: function (data) {
          if (data.length > 0) {
            var i = 0;
            for (i = 0; i < data.length; i++) {
              const opt = document.createElement("option");
              opt.value = data[i];
              opt.text = data[i];
              select.add(opt, null);
            }
          }
        },
      });
    });

    $(".chosen-select-role").select2({
      tags: true,
      placeholder: "Role *",
      allowClear: true,
      maximumSelectionLength: 1,
      createTag: function (params) {
        var term = $.trim(params.term);
        if (term === "") {
          return null;
        }
        return {
          id: term,
          text: "Add  ' " + term + " '",
          newTag: true, // add additional parameters
        };
      },
      insertTag: function (data, tag) {
        // Insert the tag at the end of the results
        data.push(tag);
        data.reverse();
      },
    });
    $(".chosen-select-role").on("select2:select", function (e) {
      $("#composer_id").click();
    });
  });
})(jQuery);
